button, input[type="text email"], select, textarea {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif;
  -webkit-appearance: none;
  border-radius: 0;
}

.wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  padding-top: 3.125rem;
  z-index: 1;
  line-height: 1.6;
  overflow: hidden;
}

.section {
  position: relative;
}

.w-container {
  max-width: 1700px;
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
}

.w-container > * {
  box-sizing: border-box;
}

.w-container .w-container {
  padding-left: 0;
  padding-right: 0;
}

.w-container::before, .w-container::after {
  content: "";
  display: table;
}

.w-container::after {
  clear: both;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 9;
  transition: -webkit-transform 0.32s ease-out;
  transition: transform 0.32s ease-out;
  transition: transform 0.32s ease-out, -webkit-transform 0.32s ease-out;
  font-size: 0;
  line-height: 1;
}

.header.is-hidden {
  -webkit-transform: translateY(-101%);
  transform: translateY(-101%);
}

.header__inner {
  height: 3.125rem;
}

._head-hide {
  display: none;
}

.link-phone {
  text-decoration: none;
  font-weight: 700;
  outline: none;
}

.link-phone--header {
  font-size: 0.875rem;
  line-height: 1.2em;
  color: #000;
}

.link-phone--callback {
  display: inline-block;
  vertical-align: top;
  font: 700 16px/1.5em Coco Gothic, Arial;
  color: #fff;
}

.text-b11 {
  font-size: 11px;
  line-height: 1.2em;
  color: rgba(0, 0, 0, 0.7);
}

.wezom {
  display: -webkit-inline-box;
  display: inline-flex;
  line-height: 1em;
  -webkit-box-align: center;
  align-items: center;
  color: #000;
}

.wezom__image {
  width: 38px;
  height: 21px;
  margin-right: .6875rem;
}

.wezom__image > svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.wezom__txt {
  font-family: Coco Gothic, Arial;
  font-weight: 700;
  font-size: 16px;
  line-height: 1em;
}

a.wezom {
  text-decoration: none;
}

a.wezom:hover {
  color: #d60000;
}

.button {
  position: relative;
  padding: 0.3125rem 1.25rem;
  height: 3.15625rem;
  max-width: 100%;
  text-decoration: none !important;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
  letter-spacing: 0.5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  text-transform: uppercase;
  vertical-align: top;
  text-align: center;
}

.button__text {
  font-family: "Arial";
  font-size: 0.875rem;
  line-height: 1.3em;
  font-weight: 700;
}

.button--default .button__text {
  color: #000;
}

.button--transparent .button__text,
.button--yellow .button__text {
  color: #fff;
}

.button--default {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: transparent;
}

.button--default:hover, .button--default:focus {
  border-color: #000;
}

.button--default:active {
  border-color: rgba(0, 0, 0, 0.5);
}

.button--transparent {
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: transparent;
}

.button--transparent:hover, .button--transparent:focus {
  border-color: #fff;
}

.button--transparent:active {
  border-color: rgba(255, 255, 255, 0.5);
}

.button--yellow {
  border: none;
  background-color: #f00;
  transition: background 0.18s ease;
}

.button--yellow:hover, .button--yellow:focus {
  background: #d60000;
}

.button--yellow:active {
  background: #f00;
}

.button--wide {
  display: block;
  width: 100%;
}

.h-list {
  display: -webkit-box;
  display: flex;
}

.h-list__item {
  position: relative;
  padding: 0 0.7rem;
}

.h-list__item > .h-list {
  position: absolute;
  top: 100%;
  left: -0.8rem;
  background: #fff;
  padding: 1rem 0.5rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease-out, visibility 0s linear;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.03);
}

.h-list__item:hover > .h-list {
  opacity: 1;
  visibility: visible;
}

.h-list__link {
  font-family: "Arial";
  font-size: 0.875rem;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.5px;
  outline: none;
}

.h-list__link:hover, .h-list__link:focus, .h-list__link.is-active {
  color: #d60000;
}

.h-list .h-list .h-list__link {
  display: inline-block;
  vertical-align: top;
  text-transform: none;
  white-space: nowrap;
  font-weight: 400;
  line-height: 2em;
}

.v-list {
  line-height: 1em;
}

.v-list__title {
  font-size: 11px;
  line-height: 1.4em;
  margin-bottom: 1.74em;
  font-weight: 700;
}

.v-list__title > a {
  text-decoration: none;
  color: #000;
}

.v-list__title > a:hover {
  color: #d60000;
}

.v-list__item {
  margin-bottom: .45rem;
}

.v-list__link {
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  outline: none;
}

.v-list__link:focus, .v-list__link:hover {
  text-decoration: underline;
}

.promocode-mobile {
  font-size: 10px;
  line-height: 11px;
  text-transform: none;
  text-align: center;
}

.fix-panel {
  position: fixed;
  z-index: 10;
  bottom: 0.5rem;
}

.fix-panel--left {
  left: 0.5rem;
}

.fix-panel--right {
  right: 0.5rem;
}

.fix-panel__item--top {
  top: 0;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  margin-left: -3px;
}

.fix-panel__item--bottom {
  bottom: 0;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  margin-left: 6px;
}

.fix-panel__balancer {
  position: absolute;
  top: 0;
}

.fix-panel__item--top .fix-panel__balancer {
  right: 0;
}

.fix-panel__item--bottom .fix-panel__balancer {
  left: 0;
}

.fix-panel__btn {
  position: relative;
  height: 2.875rem;
  width: 2.875rem;
}

.noty {
  text-align: center;
  padding-bottom: 2rem;
}

.noty__image {
  position: relative;
  margin-bottom: 5rem;
}

.noty__icon {
  position: absolute;
  display: flex;
  width: 8rem;
  height: 8rem;
  left: 50%;
  background: #d60000;
  margin-left: -4rem;
  bottom: -4rem;
  border-radius: 50%;
}

.noty__icon > svg {
  fill: #fff;
  margin: auto;
  max-width: 55%;
  max-height: 55%;
}

.noty__message {
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: 1.8rem;
  line-height: 1.4em;
}

.noty .mfp-close {
  color: #fff;
  text-shadow: 0 1px 2px #000;
  font-size: 2rem;
}

.promocode {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 11px;
  line-height: 12px;
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  white-space: nowrap;
  letter-spacing: 2px;
}

.promocode__number {
  display: block;
}

.promocode__number--is-load {
  white-space: nowrap;
  overflow: hidden;
  -webkit-animation: typing 1.5s steps(6, end);
  animation: typing 1.5s steps(6, end);
}

@-webkit-keyframes typing {
  0% {
    width: 0;
  }
  100% {
    width: 48px;
  }
}

@keyframes typing {
  0% {
    width: 0;
  }
  100% {
    width: 48px;
  }
}

.soc-link {
  white-space: nowrap;
}

.soc-link__item {
  text-decoration: none;
  font-size: 11px;
  line-height: 12px;
  padding: 0.8rem 0;
  color: rgba(0, 0, 0, 0.7);
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
  margin: -0.5rem 0 0 1rem;
}

.soc-link__item:hover {
  color: #d60000;
}

.soc-link__item:active {
  color: #f00;
}

.circle-btn {
  position: relative;
  cursor: pointer;
  background: currentColor;
  border-radius: 50%;
  transition: 0.2s ease;
  border: none;
  outline: none;
  display: -webkit-box;
  display: flex;
  padding: 0;
}

.circle-btn:hover,
.circle-btn-wrapper:hover .circle-btn {
  background: #fff;
  box-shadow: inset 0 0 0 1px;
}

.circle-btn:hover svg,
.circle-btn-wrapper:hover .circle-btn svg {
  fill: currentColor;
}

.circle-btn svg {
  margin: auto;
  max-width: 50%;
  max-height: 50%;
  fill: #fff;
}

.circle-btn--default {
  width: 3.125rem;
  height: 3.125rem;
}

.circle-btn--wide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.circle-btn--binotel {
  color: #8d5047;
}

.circle-btn--facebook {
  color: #0084ff;
}

.circle-btn--telegram {
  color: #08c;
}

.circle-btn--chat {
  color: #FF6550;
}

.circle-btn--play {
  color: #de0000;
}

.top-btn {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: 0.75rem;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  white-space: nowrap;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.22s ease;
  opacity: 0;
  visibility: hidden;
  padding: 0.25rem 0;
}

.top-btn.is-visible {
  opacity: 1;
  visibility: visible;
}

.top-btn:hover {
  color: #d60000;
}

.top-btn:hover .top-btn__arrow {
  width: 6.25rem;
}

.top-btn:hover .top-btn__text {
  color: #d60000;
}

.top-btn:active {
  color: #f00;
}

.top-btn:active .top-btn__text {
  color: #f00;
}

.top-btn__text {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  font-size: 11px;
  padding-right: 0.625rem;
  letter-spacing: 2px;
  font-family: "Arial";
}

.top-btn__arrow {
  display: block;
  vertical-align: top;
  position: relative;
  transition: width 0.3s ease;
  height: 2px;
  margin: 5px 0;
  width: 2.5rem;
  background: currentColor;
}

.top-btn__arrow::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  margin-left: -6px;
  border: 2px solid;
  border-bottom-width: 0;
  border-left-width: 0;
  width: 8px;
  height: 8px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: top right;
  transform-origin: top right;
}

.bwidget {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.bwidget:hover::before {
  top: -50%;
  right: 50%;
  content: '';
  bottom: -50%;
  width: 150%;
  position: absolute;
  z-index: 0;
}

.bwidget__icon {
  left: 1px;
  top: 1px;
  width: auto;
  height: auto;
  bottom: 1px;
  right: 1px;
  transition-delay: 0.2s;
}

.no-touchevents .bwidget:hover .bwidget__icon--telegram,
.touchevents .bwidget.is-open .bwidget__icon--telegram {
  transition-delay: 0s;
  -webkit-transform: translate3d(-115%, 0, 1px);
  transform: translate3d(-115%, 0, 1px);
}

.no-touchevents .bwidget:hover .bwidget__icon--facebook,
.touchevents .bwidget.is-open .bwidget__icon--facebook {
  transition-delay: 0s;
  -webkit-transform: translate3d(-45%, -110%, 1px);
  transform: translate3d(-45%, -110%, 1px);
}

.link-burger {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 3.125rem;
  width: 3.125rem;
  padding: 1rem;
}

.link-burger__icon {
  position: relative;
  background: #000;
  height: 2px;
  width: 100%;
  transition: width 0.2s ease;
}

.link-burger__icon::after, .link-burger__icon::before {
  position: absolute;
  content: '';
  background: #000;
  height: 2px;
  left: 0;
  transition: width 0.2s ease;
}

.link-burger__icon::after {
  top: -0.375rem;
  width: 100%;
}

.link-burger__icon::before {
  width: 50%;
  top: 0.4375rem;
}

.mm-opened .link-burger__icon {
  -webkit-animation: link-burger 0.75s 0.35s both;
  animation: link-burger 0.75s 0.35s both;
}

.mm-opened .link-burger__icon::before {
  top: 0.1875rem;
  width: 30%;
  -webkit-transform-origin: 0.45rem -0.01rem;
  transform-origin: 0.45rem -0.01rem;
  -webkit-animation: link-burger-before 0.5s 1.1s both;
  animation: link-burger-before 0.5s 1.1s both;
}

.mm-opened .link-burger__icon::after {
  top: -0.65rem;
  width: 30%;
  -webkit-transform-origin: 0.49rem 0;
  transform-origin: 0.49rem 0;
  -webkit-transform: scaleY(1) rotate(-90deg);
  transform: scaleY(1) rotate(-90deg);
  -webkit-animation: link-burger-after 0.5s 1.1s both;
  animation: link-burger-after 0.5s 1.1s both;
}

@-webkit-keyframes link-burger {
  0% {
    -webkit-transform: scaleX(1) rotate(0);
    transform: scaleX(1) rotate(0);
  }
  50% {
    -webkit-transform: scaleX(0) rotate(0deg);
    transform: scaleX(0) rotate(0deg);
  }
  100% {
    -webkit-transform: scaleX(1) rotate(-45deg);
    transform: scaleX(1) rotate(-45deg);
  }
}

@keyframes link-burger {
  0% {
    -webkit-transform: scaleX(1) rotate(0);
    transform: scaleX(1) rotate(0);
  }
  50% {
    -webkit-transform: scaleX(0) rotate(0deg);
    transform: scaleX(0) rotate(0deg);
  }
  100% {
    -webkit-transform: scaleX(1) rotate(-45deg);
    transform: scaleX(1) rotate(-45deg);
  }
}

@-webkit-keyframes link-burger-before {
  0% {
    -webkit-transform: scaleY(0) rotate(0deg);
    transform: scaleY(0) rotate(0deg);
  }
  50% {
    -webkit-transform: scaleY(0) rotate(-90deg);
    transform: scaleY(0) rotate(-90deg);
  }
  100% {
    -webkit-transform: scaleY(1) rotate(-90deg);
    transform: scaleY(1) rotate(-90deg);
  }
}

@keyframes link-burger-before {
  0% {
    -webkit-transform: scaleY(0) rotate(0deg);
    transform: scaleY(0) rotate(0deg);
  }
  50% {
    -webkit-transform: scaleY(0) rotate(-90deg);
    transform: scaleY(0) rotate(-90deg);
  }
  100% {
    -webkit-transform: scaleY(1) rotate(-90deg);
    transform: scaleY(1) rotate(-90deg);
  }
}

@-webkit-keyframes link-burger-after {
  0% {
    -webkit-transform: scaleY(0) rotate(0deg);
    transform: scaleY(0) rotate(0deg);
  }
  50% {
    -webkit-transform: scaleY(0) rotate(-90deg);
    transform: scaleY(0) rotate(-90deg);
  }
  100% {
    -webkit-transform: scaleY(1) rotate(-90deg);
    transform: scaleY(1) rotate(-90deg);
  }
}

@keyframes link-burger-after {
  0% {
    -webkit-transform: scaleY(0) rotate(0deg);
    transform: scaleY(0) rotate(0deg);
  }
  50% {
    -webkit-transform: scaleY(0) rotate(-90deg);
    transform: scaleY(0) rotate(-90deg);
  }
  100% {
    -webkit-transform: scaleY(1) rotate(-90deg);
    transform: scaleY(1) rotate(-90deg);
  }
}

.title {
  line-height: 1.2em;
  font-weight: 700;
  color: #000;
}

.title--small {
  font-size: 0.6875rem;
}

.title--group {
  font-family: "Arial";
  font-size: 0.6875rem;
  text-transform: uppercase;
  color: #d60000;
  letter-spacing: 2px;
}

.title--section {
  font-family: "Arial";
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  max-width: 213px;
}

.title--middle {
  font-family: Coco Gothic, Arial;
  font-size: 1.875rem;
}

.title--case {
  font-family: Coco Gothic, Arial;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.25rem;
  margin-bottom: 0.4rem;
}

.title--big {
  font-family: Coco Gothic, Arial;
  font-size: 1.875rem;
  line-height: 1em;
  flex-wrap: wrap;
}

.title--big.is-anim .lt-anim {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.title--big > span {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}

.title--logo {
  font-size: 1.25rem;
  line-height: 1.1em;
  text-transform: uppercase;
  font-family: Coco Gothic, Arial;
  margin-bottom: 0.5em;
}

.title--bot-line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.title--decor {
  height: 0.625rem;
  width: 1.875rem;
  margin: 0.2rem 0;
  background-color: #000;
}

.grid {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.magnific-popup {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  overflow: visible;
  background-color: #fff;
}

.magnific-popup--sm {
  max-width: 440px;
}

.magnific-popup--lg {
  max-width: 1000px;
}

.grid > * {
  min-width: 0;
  min-height: 1px;
  box-sizing: border-box;
}

._col-1 {
  flex-basis: 8.33333%;
  width: 8.33333%;
  max-width: 8.33333%;
}

._col-2 {
  flex-basis: 16.66667%;
  width: 16.66667%;
  max-width: 16.66667%;
}

._col-6 {
  flex-basis: 50%;
  width: 50%;
  max-width: 50%;
}

._col-10 {
  flex-basis: 83.33333%;
  width: 83.33333%;
  max-width: 83.33333%;
}

._col-12 {
  flex-basis: 100%;
  width: 100%;
  max-width: 100%;
}

._pb-md {
  padding-bottom: 1.5625rem;
}

._pt-md {
  padding-top: 1.5625rem;
}

._pt-sm {
  padding-top: 0.625rem;
}

._pr-md {
  padding-right: 1.5625rem;
}

._pl-md {
  padding-left: 1.5625rem;
}

._p-md {
  padding: 1.5625rem;
}

._ptb-sm {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

._mb-md {
  margin-bottom: 1.5625rem;
}

._mr-auto {
  margin-right: auto;
}

._mr-sm {
  margin-right: 0.625rem;
}

._ml-auto {
  margin-left: auto;
}

._mb-ms {
  margin-bottom: 0.9375rem;
}

._text-center {
  text-align: center;
}

._justify-center {
  -webkit-box-pack: center;
  justify-content: center;
}

._justify-between {
  -webkit-box-pack: justify;
  justify-content: space-between;
}

._items-center {
  -webkit-box-align: center;
  align-items: center;
}

._flex-grow {
  -webkit-box-flex: 1;
  flex-grow: 1;
}

._flex-nowrap {
  flex-wrap: nowrap;
}

._flex-noshrink {
  flex-shrink: 0;
}

._color-black {
  color: black;
}

._min-height {
  min-height: 100%;
}

._height {
  height: 100%;
}

._mb-ms {
  margin-bottom: .9375rem;
}

._text-center {
  text-align: center;
}

.grid--hspace-def {
  margin-left: -1.25rem;
}

.grid--hspace-def > * {
  padding-left: 1.25rem;
}

.grid--hspace-xl {
  margin-left: -1.875rem;
}

.grid--hspace-xl > * {
  padding-left: 1.875rem;
}

._color-white {
  color: #fff;
}

.valuation-section .valuation-content {
  background: white;
  padding: 3rem;
}

@media only screen and (min-width: 480px) {
  ._sm-col-6 {
    flex-basis: 50%;
    width: 50%;
    max-width: 50%;
  }
  ._sm-pl-ms {
    padding-left: 0.9375rem;
  }
  ._sm-hide {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .button {
    padding: 0.3125rem 1.375rem;
  }
}

@media only screen and (min-width: 768px) {
  ._md-col-6 {
    flex-basis: 50%;
    width: 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 1025px) {
  .wrapper {
    padding-left: 4.5vw;
    padding-right: 4.5vw;
    padding-top: 4.375rem;
  }
  .header__inner {
    height: 4.375rem;
  }
  .header {
    left: 4.5vw;
    right: 4.5vw;
  }
  .fix-panel {
    z-index: 3;
    width: 4.5vw;
    min-height: 25rem;
    top: 6.25rem;
    bottom: 6.25rem;
  }
  .fix-panel--left {
    left: 0;
  }
  .fix-panel--right {
    right: 0;
  }
  .fix-panel__item {
    position: absolute;
    left: 50%;
    width: 12px;
    height: 12px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .fix-panel__btn {
    position: absolute;
    margin: -1.4375rem 0 0 -1.4375rem;
    top: 50%;
    left: 50%;
  }
  .no-touchevents .bwidget:hover .bwidget__icon--telegram,
  .touchevents .bwidget.is-open .bwidget__icon--telegram {
    -webkit-transform: translate3d(-95%, 55%, 1px);
    transform: translate3d(-95%, 55%, 1px);
  }
  .no-touchevents .bwidget:hover .bwidget__icon--facebook,
  .touchevents .bwidget.is-open .bwidget__icon--facebook {
    -webkit-transform: translate3d(-95%, -55%, 1px);
    transform: translate3d(-95%, -55%, 1px);
  }
  ._def-p-def {
    padding: 3.125rem;
  }
  ._def-pb-lg {
    padding-bottom: 6.25rem;
  }
  ._def-pb-md {
    padding-bottom: 1.5625rem;
  }
  ._def-ptb-none {
    padding-top: 0;
    padding-bottom: 0;
  }
  ._def-mb-md {
    margin-bottom: 1.5625rem;
  }
  ._def-pt-def {
    padding-top: 3.125rem;
  }
  ._def-text-left {
    text-align: left;
  }
  ._def-justify-between {
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .grid--def-hspace-sm {
    margin-left: -0.3125rem;
  }
  .grid--def-hspace-sm > * {
    padding-left: 0.3125rem;
  }
  ._def-col-3 {
    flex-basis: 25%;
    width: 25%;
    max-width: 25%;
  }
  ._def-col-4 {
    flex-basis: 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
  }
  ._def-mb-sm {
    margin-bottom: 0.625rem;
  }
  ._def-flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
  ._def-hide {
    display: none;
  }
}

@media only screen and (min-width: 1280px) {
  .button {
    padding: 0.3125rem 2.5rem;
  }
  .h-list__item {
    padding: 0 1rem;
  }
  ._lg-pl-md {
    padding-left: 1.5625rem;
  }
}

@media only screen and (min-width: 1366px) {
  .wrapper {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 100px;
  }
  .header {
    left: 6.25rem;
    right: 6.25rem;
  }
  .header__inner {
    height: 6.25rem;
  }
  .fix-panel {
    top: 9.375rem;
    width: 6.25rem;
    bottom: 9.375rem;
  }
  .fix-panel__btn {
    height: 3.75rem;
    width: 3.75rem;
    margin: -1.875rem 0 0 -1.875rem;
  }
  .grid--xl-hspace-md {
    margin-left: -0.9375rem;
  }
  .grid--xl-hspace-md > * {
    padding-left: 0.9375rem;
  }
  ._xl-pl-lg {
    padding-left: 6.25rem;
  }
}

@media only screen and (min-width: 1600px) {
  ._head-hide {
    display: block;
  }
}

@media only screen and (max-width: 479px) {
  ._sm-show {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  ._def-show {
    display: none;
  }
}

@media only screen and (max-width: 1365px) {
  ._xl-show {
    display: none;
  }
}
